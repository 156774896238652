import React from "react";
import Layout from "../components/layout"

const EventsPage = ({ data }) => (
    <Layout>
        <h3>Software Developer Events</h3>
        <p>
            Every year I try to visit at least the following events for software developers where topics are discussed that deal with 
            Java, Open Source and Cloud Computing technologies.
            These are the biggest events in the Netherlands and surroundings.
        </p>
        <p>
          <h5>
            <a href="https://jspring.nl/" target="_blank" rel="noopener noreferrer">J-Spring</a>
          </h5>
          Somewhere in May
        </p>
        <p>
          <h5>
            <a href="https://jfall.nl/" target="_blank" rel="noopener noreferrer">J-Fall</a>
          </h5>
          Somewhere in November
        </p>
        <p>
          <h5>
            <a href="https://devoxx.be/" target="_blank" rel="noopener noreferrer">Devoxx Belgium</a>
          </h5>
          Somewhere in October
        </p>
    </Layout>
)

export default EventsPage;
